<template>

<!-- <div class="js-slider stage-video block-stage block-stage--regular stage-video--disable-nav" data-slider-stage="" data-slider-stage-disable="stage-video--disable-nav" data-slider-video="" data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing">
    <div class="js-slider-items block-stage__items stage-video__items slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
            <div class="slick-track" style="opacity: 1; width: 1903px; transform: translate3d(0px, 0px, 0px);" role="listbox">
                <div class="block-stage__item slick-slide slick-current slick-active" style="width: 1903px;" tabindex="-1" role="option" aria-describedby="slick-slide00" data-slick-index="0" aria-hidden="false">
                    <picture class="block-stage__picture block-stage__picture--only">
                        <source media="(max-width: 360px)" srcset="https://www.dbschenker.com/resource/image/640936/4:3/360/0/a01554329661adfad4bcfb479b98f984/oQ/press-center-news-newsroom--global-en-.jpg">
                        <source media="(max-width: 479px)" srcset="https://www.dbschenker.com/resource/image/640936/4:3/479/0/a01554329661adfad4bcfb479b98f984/Kj/press-center-news-newsroom--global-en-.jpg">
                        <source media="(max-width: 767px)" srcset="https://www.dbschenker.com/resource/image/640936/4:3/767/0/a01554329661adfad4bcfb479b98f984/qj/press-center-news-newsroom--global-en-.jpg">
                        <source media="(max-width: 1024px)" srcset="https://www.dbschenker.com/resource/image/640936/4:1/1024/0/1533e807ebd501d7f453a948c0df4339/aq/press-center-news-newsroom--global-en-.jpg">
                        <source media="(min-width: 1025px)" srcset="https://www.dbschenker.com/resource/image/640936/4:1/1920/0/1533e807ebd501d7f453a948c0df4339/Sv/press-center-news-newsroom--global-en-.jpg">
                        <img src="" alt="DB Schenker Flags in the Sky" class="block-stage__img">
                    </picture>
                    <div class="block-stage__content wrapper wrapper--gutter"></div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="container">
  <!-- <h1>Equipo de Trabajo</h1> -->

    <div class="row">

        
        <!--         
        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/RENE-A.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Rene Alberto Imboden</h5>
                    <p class="card-text">Managing Director</p>
                    <a href="mailto:ra.imbodem@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                </div>
            </div>
        </div> 
        -->

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/ADRIANA-F.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Adriana Farfan</h5>
                    <p class="card-text">Finance Manager</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:adriana.farfan@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

        <div class="col-sm-4" style="padding: 40px;">

        </div>

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/SARA.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Sara Gurierrez</h5>
                    <p class="card-text">Country Sales Director</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:sara.gutierrez@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>    
    </div>

    <div class="row">

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/FERNANDA.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Fernanda Perez</h5>
                    <p class="card-text">Country Operation Manager</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:fernanda.perez@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>  

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/CLAUDIA.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Claudia Tascón</h5>
                    <p class="card-text">Pricing Manager</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:claudia.tascon@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>


        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/EDWIN.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Edwin Rodriguez</h5>
                    <p class="card-text">Regional Sales Director</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:edwin.rodriguez@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>

        </div>        

    </div>

    <div class="row">

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/JAVIER.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Javier Fernandez</h5>
                    <p class="card-text">Warehousing and Distribution Manager</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:javier.fernandez@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/ANGELA_SANCHEZ.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Angela Sanchez</h5>
                    <p class="card-text">Claims Manager</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="#" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/ANGELA.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Angela Ocampo</h5>
                    <p class="card-text">Branch Office Manager - Pereira</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:angela.ocampo@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>

        </div>        
    </div>

    <div class="row">

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/VIVIANA.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Viviana Lopez</h5>
                    <p class="card-text">Branch Office Manager - Cali</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="mailto:viviana.lopez@sunrisecargo.com" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/ANDRES.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Andrés Gómez</h5>
                    <p class="card-text">Product Manager Air</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="#" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/FABIAN.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Fabian Jarro</h5>
                    <p class="card-text">Product Manager Ocean</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="#" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

    </div>

    <div class="row">

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/JUAN.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Juan Pablo Rubio</h5>
                    <p class="card-text">IT Manager</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="#" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

        <div class="col-sm-4" style="padding: 40px;">

        </div>

        <div class="col-sm-4" style="padding: 40px;">
            <div class="card sd-2" style="align-items: center;">
                <img style="width: 80%; padding-top:20px;" src="/imagenes/employees/YANNET.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Yannet Hernandez</h5>
                    <p class="card-text">HR Manager</p>
                    <!-- <button class="btn btn--solid btn--default btn--pointer btn--full tracking-search__btn"> Contácteme </button> -->
                    <a href="#" class="btn btn--border btn--default hide show-portable " tabindex=""><span>Contácteme</span></a>
                    <!-- <a href="#" class="btn btn-default">Contácteme</a> -->
                </div>
            </div>
        </div>

    </div>

</div>

</template>

<script>
export default {

}
</script>
